import 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import DealLayout from '../components/deals/DealLayout';
import HomeHero from '../components/home/HomeHero';

const ConditionView = ({ data }) => {
  const { condition, products } = data;
  if (!condition) return null;
  return (
    <Layout>
      <Seo
        title={condition.title}
        description={condition.description || condition?.pageTitle}
      />
      <HomeHero
        title={condition.pageTitle}
        description={condition.description}
        fluid={
          condition.image && condition.image.asset
            ? condition.image.asset.gatsbyImageData
            : ''
        }
      />
      <DealLayout data={products.nodes} />
    </Layout>
  );
};

ConditionView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ConditionView;

export const query = graphql`
  query ($slug: String!) {
    condition: sanityCondition(slug: { current: { eq: $slug } }) {
      _id
      title
      slug {
        current
      }
      pageTitle
      description
      image {
        asset {
          gatsbyImageData
          url
        }
      }
    }
    products: allSanityProduct(
      sort: { fields: order, order: ASC }
      filter: { condition: { slug: { current: { eq: $slug } } } }
    ) {
      nodes {
        id
        title
        body
        description
        numberOfJets
        numberOfSeats
        price
        slug {
          current
        }
        colors {
          title
          value
        }
        condition {
          _id
          title
          slug {
            current
          }
        }
        image {
          asset {
            gatsbyImageData
          }
        }
        brand {
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
